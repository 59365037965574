import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
// import Modal from '../components/modal';

import Layout from '../components/layout';
import SEO from '../components/seo';
// import PolozheniePedSovet from '../components/polozhenie_ped_sovet';
import polozheniePedSovet2023 from '../../static/polozhenie_o_pedsovete_ecp.pdf';

function Structure({ data }) {
    // const [modalOpened, setModalOpened] = useState(false);
    // const handleModalClose = () => setModalOpened(false);
    return (
        <Layout>
            <SEO
                keywords={[
                    `Структура образовательной организации`,
                    `Профф`,
                    `Пыть-Ях`,
                    `Профессиональное образование`,
                ]}
                title="About"
            />

            <section className="flex flex-col items-center ">
                <h1 className="text-center text-2xl md:text-3xl text-gray-900 mb-6">
                    Структура и органы управления образовательной организации
                </h1>

                {/* <img src={StructureImage} alt='image' /> */}
                <div className="w-full bg-white p-4  rounded shadow-lg">
                    <h2 className="text-green-700 text-2xl mb-4 text-center">
                        Организационная структура образовательного учреждения
                    </h2>
                    <Img
                        fluid={data.structure.childImageSharp.fluid}
                        alt="Структура органов управления"
                    />
                </div>
                <div className="w-full bg-white p-4 py-6 my-4 rounded shadow-lg">
                    <h2 className="text-green-700 text-2xl mb-4 text-center">
                        Органы управления образовательной организации
                    </h2>
                    {/* <div> */}
                    <ul className=" list-disc px-4">
                        <li>
                            Высшим органом управления Учреждением является
                            Учредитель;
                        </li>
                        <li>
                            Единоличным исполнительным органом Учреждения
                            является генеральный директор, который осуществляет
                            текущее руководство деятельностью Учреждения;
                        </li>
                        <li>
                            Действующими коллегиальными органами управления
                            являются:
                            <ul className="list-disc pl-4">
                                <li>
                                    общее собрание работников Учреждения (в
                                    данный коллегиальный орган входят все
                                    работники Учреждения);
                                </li>
                                <li>
                                    педагогический совет, в который входят все
                                    педагогические работники и генеральный
                                    директор Учреждения.
                                </li>
                            </ul>
                            <li>
                                РУКОВОДСТВО ОБРАЗОВАТЕЛЬНЫМ УЧРЕЖДЕНИЕМ
                                ОСУЩЕСТВЛЯЕТ ГЕНЕРАЛЬНЫЙ ДИРЕКТОР ЧОУ ДПО
                                «ПРОФФ» ПРОТОПОПОВА ЕЛЕНА СЕРГЕЕВНА.
                            </li>
                            <li>
                                СТРУКТУРНЫЕ ПОДРАЗДЕЛЕНИЯ В СОСТАВЕ
                                ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИИ ОТСУТСТВУЮТ.
                                РАБОТНИКИ ЧОУ ДПО «ПРОФФ» ВЫПОЛНЯЮТ ФУНКЦИИ В
                                ПРЕДЕЛАХ ДОЛЖНОСТНЫХ ОБЯЗАННОСТЕЙ И ПОДЧИНЯЮТСЯ
                                НЕПОСРЕДСТВЕННО ГЕНЕРАЛЬНОМУ ДИРЕКТОРУ.
                            </li>
                        </li>
                    </ul>
                </div>
                {/* </div> */}
                <div className="w-full bg-white p-4  rounded shadow-lg">
                    <h2 className="text-green-700 text-2xl mb-4 text-center">
                        Структура органов управления образовательной организации
                    </h2>

                    <Img
                        // className='w-full'
                        fluid={data.structure2.childImageSharp.fluid}
                        alt="Структура "
                    />
                </div>
                <div
                    className="w-full bg-white p-4 mt-6 rounded shadow-lg cursor-pointer"
                    // onClick={() => setModalOpened(true)}
                >
                    {/* <h2 className="text-green-700 text-2xl mb-4 text-center">
                        Положение о педагогическом совете
                    </h2> */}
                    <a
                        href={polozheniePedSovet2023}
                        target="blank"
                        rel="noopener noreferrer"
                    >
                        <h2 className="text-green-700 text-2xl text-center">
                            Положение о педагогическом совете
                        </h2>
                    </a>
                </div>
                {/* <Img fluid={data.structure2.childImageSharp.fluid} alt='Структура ' /> */}
            </section>
            {/* {modalOpened && (
                <Modal onClose={handleModalClose}>
                    <PolozheniePedSovet />
                </Modal>
            )} */}
        </Layout>
    );
}

export const query = graphql`
    query MyQuery1 {
        structure: file(relativePath: { eq: "images/structure.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(maxWidth: 1700, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        structure2: file(relativePath: { eq: "images/structure_2.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(maxWidth: 1700, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

Structure.propTypes = {
    data: PropTypes.object,
};

export default React.memo(Structure);
